<template>
<div>
  <div class="patientInfo">
    <el-form ref="form2" :model="form2" size='mini' label-width="auto" :inline="true" >
      <el-row>
        <el-col :span="4">
          <el-form-item label="医院">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input v-model="form2.hospital_name" style="width: 90%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="患者">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input v-model="form2.patient_name" style="width: 90%;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <el-form-item label="医生">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input v-model="form2.doctor_name" style="width: 90%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年龄">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input v-model="form2.patient_age" style="width: 90%;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <el-form-item label="手术日期">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-date-picker type="date" placeholder="选择日期" v-model="form2.operation_date" style="width: 90%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="性别">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-select v-model="form2.patient_sex" placeholder="请选择性别" style="width: 90%;">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4">
          <el-form-item label="手术眼别">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-select v-model="form2.patient_eye" placeholder="请选择手术眼别" style="width: 90%;">
              <el-option label="左眼" value="左眼"></el-option>
              <el-option label="右眼" value="右眼"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="病历号">
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input v-model="form2.case_number" style="width: 90%;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

  <div class="Calculator">
    <!-- label-width="145px"  -->
    <el-form ref="form" :model="form" size='large' label-width="auto" :inline="true">
      <el-row>
        <el-col :span="12">
          <el-form-item label="球镜" prop="Spherical_mirror" 
          :rules="[
            { required: true, message: '请输入球镜数据' },
          ]">
            <el-input 
            class="w-50 m-2" 
            size="large" 
            v-model="form.Spherical_mirror"
            v-number-input.negative="{ min: -30, max: 30 }">
              <template slot="suffix">D</template>
            </el-input>
            <!-- <el-input-number 
              class="w-50 m-2" 
              size="large" 
              :controls="false"
              v-model="form.Spherical_mirror" 
              placeholder="球镜(D)"></el-input-number> -->
          </el-form-item>
        <!-- <el-input-number :controls="false"></el-input-number> 
        { type: 'number', message: '请输入数字' }, -->
        </el-col>
        <el-col :span="12">
          <el-form-item label="柱镜" prop="Column_mirror" 
          :rules="[
            { required: true, message: '请输入柱镜数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-model="form.Column_mirror" 
              v-number-input.negative="{ min: -10, max: 10 }">
                <template slot="suffix">D</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="轴位" prop="Axial_position" 
          :rules="[
            { required: true, message: '请输入轴位数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-model="form.Axial_position" 
              v-number-input="{ min: 0, max: 180 }">
                <template slot="suffix">°</template>
              </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="镜眼距">
            <el-input
              class="w-50 m-2" 
              size="large" 
              :disabled="true"
              v-model="distance_of_eye"
              placeholder="12.0">
                <template slot="suffix">mm</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="平坦K值" prop="K1" 
          :rules="[
            { required: true, message: '请输入平坦K值数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-model="form.K1" 
              v-number-input="{ min: 30, max: 60 }">
                <template slot="suffix">D</template>
              </el-input>
              
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="平坦子午线" prop="K1_direction" 
          :rules="[
            { required: true, message: '请输入平坦子午线数据' },
          ]">
            <el-input
              class="w-50 m-2" 
              size="large" 
              v-number-input="{ min: 0, max: 180 }"
              v-model="form.K1_direction">
                <template slot="suffix">°</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="陡峭K值" prop="K2" 
          :rules="[
            { required: true, message: '请输入陡峭K值数据' },
          ]">
            <el-input
              class="w-50 m-2" 
              size="large" 
              v-number-input="{ min: 30, max: 60 }"
              v-model="form.K2">
                <template slot="suffix">D</template>
              </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="陡峭子午线" prop="K2_direction" 
          :rules="[
            { required: true, message: '请输入陡峭子午线数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              :disabled="true"
              v-number-input.negative
              v-model="form.K2_direction">
                <template slot="suffix">°</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="角膜厚度" prop="T" 
          :rules="[
            { required: true, message: '请输入角膜厚度数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-number-input="{ min: 0.01, max: 0.9 }"
              v-model="form.T" >
                <template slot="suffix">mm</template>
              </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="前房深度" prop="ACD" 
          :rules="[
            { required: true, message: '请输入前房深度数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-number-input="{ min: 2.5, max: 4.5 }"
              v-model="form.ACD" >
                <template slot="suffix">mm</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="白到白（角膜横径）" prop="WhitetoWhite" label-width="200px"
          :rules="[
            { required: true, message: '请输入角膜横径数据' },
          ]">
            <el-input 
              class="w-50 m-2" 
              size="large" 
              v-number-input="{ min: 10, max: 13.5 }"
              v-model="form.WhitetoWhite" >
                <template slot="suffix">mm</template>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="spacial_button">
        <el-button type="primary" @click="submitForm('form')">计算</el-button>
        <el-button @click="resetForm('form')">清空</el-button>
      </el-form-item>
    </el-form>
  </div>


  <div class="Result">
    <span class="result_title">计算结果</span>
    <el-table
      ref="resultTable"
      :data="return_tableData"
      style="width: 100%"
      border
      :row-class-name="tableRowClassName"
      highlight-selection-row
      @selection-change="handleCurrentChange"
      @row-click="clickColumn"
      max-height="477"
      >
      <el-table-column
        type="selection"
        show-overflow-tooltip
        width="55">
      </el-table-column>
      <el-table-column
        prop="xzjtqjds"
        label="选中晶体球镜度数"
        >
      </el-table-column>
      <el-table-column
        prop="xzjtzjds"
        label="选中晶体柱镜度数"
        >
      </el-table-column>
      <el-table-column
        prop="yqqj"
        label="预期球镜"
        >
      </el-table-column>
      <el-table-column
        prop="yqsg"
        label="预期散光"
        >
      </el-table-column>
      <el-table-column
        prop="zx"
        label="轴向"
        >
      </el-table-column>
      <el-table-column
        prop="dxqj"
        label="等效球镜"
        >
      </el-table-column>
    </el-table>

    <!-- <el-descriptions class="margin-top" title="计算结果" :column="3"  border>
      <el-descriptions-item>
        <template slot="label">
          球镜
        </template>
        {{tableData[0]?tableData[0].qj:""}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          柱镜
        </template>
        {{tableData[0]?tableData[0].zj:""}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          轴位
        </template>
        {{tableData[0]?tableData[0].zw:""}}
      </el-descriptions-item>
    </el-descriptions> -->

    <el-descriptions class="margin-top" title="晶体规格" :column="1" border style="width: 45%;">
      <el-descriptions-item>
        <template slot="label">
          人工晶状体尺寸
        </template>
        <!-- {{recommended_icl?recommended_icl:""}} -->
        <template>
          <el-select v-model="recommended_icl" placeholder="请选择">
            <el-option
              v-for="item in recommended_icl_arr"
              :key="item.label"
              :label="item.label"
              :value="item.label">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </template>
      </el-descriptions-item>
    </el-descriptions>

    <el-button size="large" class="dayin .no-print" type="primary" @click="dayin()">打印预览</el-button>
    <Preview ref="Preview" :form="form" :form2="form2" :result_Spherical_mirror="result_Spherical_mirror" 
        :result_Column_mirror="result_Column_mirror" :result_Axial_position="result_Axial_position" 
        :recommended_icl="recommended_icl" :return_tableData="return_tableData" :currentRow="currentRow"></Preview>
  </div>
  </div>

</template>

<script>
  import Preview from './Preview';

export default {
  name: 'HelloWorld',
  data() {
    return {
      form2: {
        hospital_name : '',          //医院
        doctor_name : '',            //医生
        operation_date : '',  //日期
        patient_name : '',           //患者
        patient_sex : '',             //患者性别
        patient_age : '',            //患者年龄
        patient_eye : '',           //手术眼睛
        case_number : '',            //病历号
      },
      form: {
        Spherical_mirror : '',          //球镜(D)
        Column_mirror : '',             //柱镜
        Axial_position : '',            //轴位
        K1: '',
        K1_direction:'',
        K2: '',
        K2_direction:'',
        T:'',                           //角膜厚度
        ACD:'',                         //前房深度
        WhitetoWhite: '',
      },
      distance_of_eye : 12.0,  //镜眼距（mm）
      K : 0,  //K

      result_yzq : 0,    //预期轴位方向屈光力
      result_szq : 0,      //散光轴位方向屈光力
      result_szq_direction : 0,      //散光轴位方向
      result_djc : 0,      //等效角膜接触镜（D）
      
      result_vertical_yzq : 0,   //预期垂直轴位方向屈光力
      result_vertical_szq : 0,      //垂直散光轴位方向屈光力
      result_vertical_szq_direction : 0,      //垂直散光轴位方向
      result_vertical_djc : 0,      //等效角膜接触镜（D）

      result_equivalent_ydqd : 0,   //预期等效球镜度数
      result_equivalent_dq : 0,   //等效球镜（D）
      result_equivalent_dq_direction : 0,   //等效球镜（D）的方向
      result_equivalent_djc : 0,      //等效角膜接触镜（D）


      result_Spherical_mirror : 0,             //1-球镜
      result_vertical_Spherical_mirror : 0,    //2-球镜
      result_Column_mirror : 0,                //1-柱镜
      result_vertical_Column_mirror : 0,       //2-柱镜
      result_Axial_position : 0,               //1-轴位
      result_vertical_Axial_position : 0,      //2-轴位

      result_calculation_dq : 0,   //1-等效球镜
      result_dq : 0,               //2-等效球镜
      tableData : [],
      dxqj_tableData : [],
      recommended_icl : 0,
      return_tableData : [],
      k1_icl : 0,
      k2_icl : 0,
      recommended_icl_arr : [{
          value: '',
          label: '12.1'
        }, {
          value: '',
          label: '12.6'
        }, {
          value: '',
          label: '13.2'
        }, {
          value: '',
          label: '13.7'
        }],
      currentRow: [],
    }
  },
  components: {
    Preview
  },
  methods: {
    //formName
      submitForm(formName) {
        var temp , yzq , vertical_yzq , equivalent_ydqd;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formParseFloat();

            this.k1_icl = this.form.K1*Math.pow(Math.cos((Math.PI/180)*((this.form.K1_direction-this.form.Axial_position)*Math.sign(this.form.Column_mirror))),2)+this.form.K2*Math.pow(Math.sin((Math.PI/180)*((this.form.K1_direction-this.form.Axial_position)*Math.sign(this.form.Column_mirror))),2);
            this.k2_icl = this.form.K1*Math.pow(Math.sin((Math.PI/180)*((this.form.K1_direction-this.form.Axial_position)*Math.sign(this.form.Column_mirror))),2)+this.form.K2*Math.pow(Math.cos((Math.PI/180)*((this.form.K1_direction-this.form.Axial_position)*Math.sign(this.form.Column_mirror))),2);


            // 计算普通常数
            this.K = (this.form.K1 + this.form.K2) / 2;

            //计算预期轴位方向屈光力
            this.result_szq = this.form.Spherical_mirror;
            this.result_szq_direction = this.form.Axial_position > 90 ? (this.form.Axial_position-90) : (this.form.Axial_position+90);
            temp = (1/((1/this.result_szq)-(this.distance_of_eye/1000)));
            yzq = (1336/(1336/(this.K+temp)-this.form.T-this.form.ACD-0.1)-1336/(1336/this.K-this.form.T-this.form.ACD-0.1));
            this.result_djc = parseFloat(temp.toFixed(2));
            this.result_yzq = yzq.toFixed(8);
            
            //计算预期垂直轴位方向屈光力
            this.result_vertical_szq = this.form.Spherical_mirror + this.form.Column_mirror; 
            this.result_vertical_szq_direction = this.form.Axial_position; 
            temp = (1/((1/this.result_vertical_szq)-(this.distance_of_eye/1000)));
            vertical_yzq = (1336/(1336/(this.K+temp)-this.form.T-this.form.ACD-0.1)-1336/(1336/this.K-this.form.T-this.form.ACD-0.1));
            this.result_vertical_djc = parseFloat(temp.toFixed(2));
            this.result_vertical_yzq = vertical_yzq.toFixed(8);

            //计算预期等效球镜度数
            this.result_equivalent_dq = this.form.Spherical_mirror + this.form.Column_mirror/2; 
            this.result_equivalent_dq_direction = this.form.Axial_position > 90 ? (this.form.Axial_position-90) : (this.form.Axial_position+90);
            temp = (1/((1/this.result_equivalent_dq)-(this.distance_of_eye/1000)));
            equivalent_ydqd = (1336/(1336/(this.K+temp)-this.form.T-this.form.ACD-0.1)-1336/(1336/this.K-this.form.T-this.form.ACD-0.1));
            this.result_equivalent_djc = parseFloat(temp.toFixed(2));
            this.result_equivalent_ydqd = equivalent_ydqd.toFixed(8);

            //计算球镜、柱镜、轴位
            //精度归算
            var rsm_r;
            var rsm = vertical_yzq.toFixed(4);
            var rsm_i = rsm.toString().split(".")[0];
            var rsm_f = parseInt(rsm.toString().split(".")[1]);
            if(rsm_f < 1250){
              rsm_r = rsm_i + ".00";
            }else if(rsm_f < 3750){
              rsm_r = rsm_i + ".25";
            }else if(rsm_f < 6250){
              rsm_r = rsm_i + ".50";
            }else if(rsm_f < 8750){
              rsm_r = rsm_i + ".75";
            }else{
              rsm_r = parseInt(rsm_i) + 1;
              rsm_r = rsm_r + ".00"
            }
            // this.result_Spherical_mirror = this.result_vertical_yzq;
            this.result_Spherical_mirror = rsm_r;
            this.result_vertical_Spherical_mirror = this.result_yzq;
            
            //精度归算
            var rcm_r;
            var rcm = (yzq - vertical_yzq).toFixed(3);
            var rcm_i = rcm.toString().split(".")[0];
            var rcm_f = parseInt(rcm.toString().split(".")[1]);
            if(rcm_f < 250){
              rcm_r = rcm_i + ".00";
            }else if(rcm_f < 750){
              rcm_r = rcm_i + ".50";
            }else{
              rcm_r = parseInt(rcm_i) + 1;
              rcm_r = rcm_r + ".00"
            }
            // this.result_Column_mirror = (yzq - vertical_yzq).toFixed(8);
            this.result_Column_mirror = rcm_r;
            this.result_vertical_Column_mirror = (vertical_yzq - yzq).toFixed(8);
            this.result_Axial_position = this.form.Axial_position > 90 ? (this.form.Axial_position-90) : (this.form.Axial_position+90);
            this.result_vertical_Axial_position = this.form.Axial_position;

            var column1 = {
              qj : this.result_Spherical_mirror,
              zj : this.result_Column_mirror,
              zw : this.result_Axial_position
            };
            var column2 = {
              qj : this.result_vertical_Spherical_mirror,
              zj : this.result_vertical_Column_mirror,
              zw : this.result_vertical_Axial_position
            };
            this.tableData = [];
            this.tableData.push(column1);
            this.tableData.push(column2);

            //计算等效球镜
            this.result_calculation_dq = ((vertical_yzq+yzq)/2).toFixed(8);
            //精度归算
            var rcd_r;
            var rcd = ((vertical_yzq+yzq)/2).toFixed(4);
            var rcd_i = rcd.toString().split(".")[0];
            var rcd_f = parseInt(rcd.toString().split(".")[1]);
            if(rcd_f < 1250){
              rcd_r = rcd_i + ".00";
            }else if(rcd_f < 3750){
              rcd_r = rcd_i + ".25";
            }else if(rcd_f < 6250){
              rcd_r = rcd_i + ".50";
            }else if(rcd_f < 8750){
              rcd_r = rcd_i + ".75";
            }else{
              rcd_r = parseInt(rcd_i);
              if (rcd_r >= 0) {
                rcd_r = rcd_r + 1;
              } else {
                rcd_r = rcd_r - 1;
              }
              rcd_r = rcd_r + ".00"
            }
            this.generateTable(parseFloat(rcd_r));
            this.result_dq = this.result_equivalent_ydqd;
            var c1 = {
              dq : this.result_calculation_dq
            };
            var c2 = {
              dq : this.result_dq
            };
            this.dxqj_tableData = [];
            this.dxqj_tableData.push(c1);
            this.dxqj_tableData.push(c2);

            //计算推荐尺寸
            var ww = this.form.WhitetoWhite.toFixed(1);
            var morethen_3_5 = this.form.ACD > 3.5;
            var recommended_ = 0;
            if (ww < 10.7 || ww > 12.9
                  ||(this.form.ACD < 2.8) || (this.form.ACD > 4.5)) {
              this.$alert('Not Recommended', '推荐人工晶状体尺寸', {
                confirmButtonText: '确定'
              });
            }else if(ww >= 10.7 && ww <= 11){
                recommended_ = 12.1;
            }else if(ww == 11.1 && !morethen_3_5){
                recommended_ = 12.1;
            }else if(ww == 11.1 && morethen_3_5){
                recommended_ = 12.6;
            }else if(ww >= 11.2 && ww <= 11.4){
                recommended_ = 12.6;
            }else if(ww >= 11.5 && ww <= 11.6 && !morethen_3_5){
                recommended_ = 12.6;
            }else if(ww >= 11.5 && ww <= 11.6 && morethen_3_5){
                recommended_ = 13.2;
            }else if(ww >= 11.7 && ww <= 11.8 && this.form.ACD <= 2.9){
                recommended_ = 12.6;
            }else if(ww >= 11.7 && ww <= 11.8 && this.form.ACD > 2.9){
                recommended_ = 13.2;
            }else if(ww >= 11.9 && ww <= 12.2){
                recommended_ = 13.2;
            }else if(ww == 12.3 && this.form.ACD <= 3.5){
                recommended_ = 13.2;
            }else if(ww >= 12.4 && ww <= 12.6 && this.form.ACD <= 3.1){
                recommended_ = 13.2;
            }else {
                recommended_ = 13.7;
            }
            for (const obj of this.recommended_icl_arr) {
              if (obj.label == recommended_) {
                obj.value = "推荐";
              }
            }
            this.recommended_icl_arr;
            this.recommended_icl = recommended_;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      formParseFloat(){
        this.form.Spherical_mirror = parseFloat(this.form.Spherical_mirror)
        this.form.Column_mirror = parseFloat(this.form.Column_mirror)
        this.form.Axial_position = parseFloat(this.form.Axial_position)
        this.form.K1 = parseFloat(this.form.K1)
        this.form.K1_direction = parseFloat(this.form.K1_direction)
        this.form.K2 = parseFloat(this.form.K2)
        this.form.K2_direction = parseFloat(this.form.K2_direction)
        this.form.T = parseFloat(this.form.T)
        this.form.ACD = parseFloat(this.form.ACD)
        this.form.WhitetoWhite = parseFloat(this.form.WhitetoWhite)
        if (this.form2.operation_date) {
          let dateFormat =/^(\d{4})-(\d{2})-(\d{2})$/;
          if(!dateFormat.test(this.form2.operation_date)){
            this.form2.operation_date = this.form2.operation_date.format("yyyy-MM-dd")
          }
        }
      },
      resetForm(formName){
        this.$refs[formName].resetFields();
        this.clearResult();
      },
      clearResult(){
        this.K = 0;  //K
        this.result_yzq = 0;    //预期轴位方向屈光力
        this.result_szq = 0;      //散光轴位方向屈光力
        this.result_szq_direction = 0;      //散光轴位方向
        this.result_djc = 0;      //等效角膜接触镜（D）
        
        this.result_vertical_yzq = 0;   //预期垂直轴位方向屈光力
        this.result_vertical_szq = 0;      //垂直散光轴位方向屈光力
        this.result_vertical_szq_direction = 0;      //垂直散光轴位方向
        this.result_vertical_djc = 0;      //等效角膜接触镜（D）

        this.result_equivalent_ydqd = 0;   //预期等效球镜度数
        this.result_equivalent_dq = 0;   //等效球镜（D）
        this.result_equivalent_dq_direction = 0;   //等效球镜（D）的方向
        this.result_equivalent_djc = 0;      //等效角膜接触镜（D）


        this.result_Spherical_mirror = 0;             //1-球镜
        this.result_vertical_Spherical_mirror = 0;    //2-球镜
        this.result_Column_mirror = 0;                //1-柱镜
        this.result_vertical_Column_mirror = 0;       //2-柱镜
        this.result_Axial_position = 0;               //1-轴位
        this.result_vertical_Axial_position = 0;      //2-轴位

        this.result_calculation_dq = 0;   //1-等效球镜
        this.result_dq = 0;               //2-等效球镜
        this.tableData = [];
        this.dxqj_tableData = [];
        this.return_tableData = [];
        this.recommended_icl = '';
        this.form2 = {
          hospital_name : '',
          doctor_name : '',
          operation_date : '',
          patient_name : '',
          patient_sex : '',
          patient_age : '',
          patient_eye : '',
          case_number : '',
        };
      },
      dayin(){
        this.$refs.Preview.init();
        // const iframe = document.createElement('div');
        // let a = this.$refs.aa.cloneNode(true);
        // let b = this.$refs.bb.cloneNode(true);
        // iframe.style.width = '210mm';
        // iframe.style.height = '297mm';
        // iframe.appendChild(a);
        // iframe.appendChild(b);

        // console.log(iframe);
        // this.$nextTick(() => {
        //   this.$print(iframe);
        // });
        // window.print();
        // this.$refs.Print.initPage();
      },
      generateTable(qjds){
        // console.log(qjds)
        this.return_tableData = [];
        var firstDs = (qjds + 2.5) > -0.5 ? -0.5 : (qjds + 2.5);
        var endDs = (qjds - 2.5) < -21 ? -21 : (qjds - 2.5);
        const xzjtzjds = 0;
        var recommended_index = 0;
        var temp_index = 0;
        for (let index = firstDs; index >= endDs; index-=0.25) {
          const jmpmqgl = 1336/(1/(1/(1336/this.K-this.form.T-this.form.ACD-0.1)+index/1336)+this.form.T+this.form.ACD+0.1)-this.K;
          const c1_icl = 1/(1/(1.336/(1.336/(1.336/(1.336/(this.k1_icl + 1/(1/this.form.Spherical_mirror - this.distance_of_eye*0.001))-(this.form.ACD+this.form.T-0.304)*0.001)-index)+(this.form.ACD+this.form.T-0.304)*0.001)-this.k1_icl)+this.distance_of_eye*0.001);
          const c2_icl = 1/(1/(1.336/(1.336/(1.336/(1.336/(this.k2_icl+1/(1/(this.form.Spherical_mirror+this.form.Column_mirror)-this.distance_of_eye*0.001))-(this.form.ACD+this.form.T-0.304)*0.001)-index)+(this.form.ACD+this.form.T-0.304)*0.001)-this.k2_icl)+this.distance_of_eye*0.001);
          const yqsg = Math.abs(c1_icl-c2_icl)*Math.sign(this.form.Column_mirror);
          const dxqj = c1_icl/2+c2_icl/2;
          const yqqj = dxqj-yqsg/2;
          var zx = 0;
          if (this.form.Column_mirror*yqsg>0) {
            zx = this.form.Axial_position;
          } else {
            let temp = this.form.Axial_position+90;
            zx = temp - (180 * parseInt(temp/180));
          }
          var c1 = {
              xzjtqjds : index.toFixed(2),  // 选中晶体球镜度数
              jmpmqgl : jmpmqgl.toFixed(2), // 对应角膜平面屈光力
              yqqj : yqqj>0?'+'+yqqj.toFixed(2):yqqj.toFixed(2),       // 预期球镜
              yqsg : yqsg>0?'+'+yqsg.toFixed(2):yqsg.toFixed(2),       // 预期散光
              zx : zx,                      // 轴向
              dxqj : dxqj>0?'+'+dxqj.toFixed(2):dxqj.toFixed(2),       // 等效球镜
              c1_icl : c1_icl,
              c2_icl : c2_icl,
              xzjtzjds : xzjtzjds
          };
          this.return_tableData.push(c1);
          this.currentRow = [];
          if (index == qjds) {
            recommended_index = temp_index;
            this.currentRow.push(c1);
          }
          temp_index++;
        }
        this.$nextTick(()=>{
          this.$refs.resultTable.toggleRowSelection(this.return_tableData[recommended_index], true);
          var showIndex = (recommended_index - 4) >= 0 ? (recommended_index - 4) : 0;
          this.tableScrollToRow(this.$refs.resultTable, showIndex);
        })
      },
      tableRowClassName({row}) {
        if(row && row === this.currentRow[0]){
          console.log(row)
          return 'warning-row';
        }
        // if (this.initRanbow && rowIndex === 4) {
        //   this.initRanbow = false;
        //   return 'warning-row';
        // }
        return '';
      },
      tableColumnClassName({row, column, rowIndex, columnIndex}) {
        if (row | column | rowIndex) {
          // console.log(row, column, rowIndex)
        }
        if (columnIndex === 0) {
          return 'first-column';
        }
        return '';
      },
      handleCurrentChange(val) {
        console.log('handleCurrentChange enter');
        console.log(val);
        this.currentRow = val;
        if (val.length > 1) {
          this.$refs.resultTable.clearSelection();
          this.$refs.resultTable.toggleRowSelection(val.pop());
        }
      },
      clickColumn(row) {
        this.currentRow.push(row);
        this.handleCurrentChange(this.currentRow);
      },
      tableScrollToRow(tableElement, rowIndex) {
        const theTableRows = tableElement.bodyWrapper.querySelectorAll('.el-table__body tbody .el-table__row');
        let scrollTop = 0;
        for (let i = 0; i < theTableRows.length; i++) {
          if (i === rowIndex) {
            break;
          }
          scrollTop += theTableRows[i].offsetHeight;
        }
        tableElement.bodyWrapper.scrollTop = scrollTop;
      }
    },
  watch:{
    'form.K1_direction'(newVal){
      let addNin = parseInt(newVal) + 90;
      this.form.K2_direction = addNin - (180 * parseInt(addNin/180));
    }
  },
    // components: {
    //   Print
    // },
    // setup(){
    //   const print = ref(null);
    //   const handleClick = () => {
    //     print.value.initPage();
    //   }
    //   return { print, handleClick, } 
    // } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.patientInfo{
  width: 40%;
  margin: 1% ;
  float: left;
  margin-top: 110px;
  padding-top: 40px;
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow:0px 0px  15px 1px rgb(214, 214, 214);
}
.Calculator{
  width: 53%;
  /* margin: 0 auto ; */
  float: right;
  align-items: center;
  margin-top: 40px;
  margin-right: 2%;
  padding-top: 40px;
  padding-right: 1%;
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow:0px 0px  15px 1px rgb(214, 214, 214);
}
.Result{
  width: 80%;
  float: right;
  margin-top: 1%;
  margin-right: 10%;
}
.result_title{
  float: left;
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  margin-bottom: 20px;
  /* font-size: 18px large; */
}
.spacial_hidden{
  visibility: hidden;
}
.spacial_button{
  width: 60%;
  padding-left: 0%;
}
.margin-top {
  margin-top: 60px;
}
.dayin{
  margin-top: 60px;
}
</style>

<style>
  .el-table .warning-row {
    background: oldlace;
    font-weight: bolder;
    font-size: medium;
  }
.first-column{
  font-weight: 700;
}
</style>
