<template>
  <div>
    <el-container>
      <el-header class="el-header">
        <Header/>
      </el-header>

      <el-main>
        <HelloWorld/>
      </el-main>

      <el-footer class="el-footer">
        <Footer/>
      </el-footer>
    </el-container>
    <!-- <img  src="./assets/Vision_Pro_logo.png.png"> -->
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Header,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.el-header{
  width: 100%;
  height: 60px;
  padding: 0;
}
.el-main {
  text-align: center;
}
.el-footer{
  width: 100%;
  /* height: 80px; */
  padding: 0;
}
</style>
