<template>
  <el-dialog title="打印预览" :visible.sync="dialogVisible" destroy-on-close>
    <div id="printMe">
      <div class="logo-box-print">
        <span class="img-box-print"><img class="img-logo-print" src="../assets/Vision_Pro_logo.png"></span>
        <span class="font-title-print">后房有晶体眼屈光矫正型人工晶状体在线计算器</span>
      </div>

      <el-descriptions style="margin-top: 20px;" title="病历信息" :column="4" size="small" border>
        <el-descriptions-item>
          <template slot="label">
            医院
          </template>
          {{form2.hospital_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            医生
          </template>
          {{form2.doctor_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            手术日期
          </template>
          {{form2.operation_date}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            手术眼别
          </template>
          {{form2.patient_eye}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            患者
          </template>
          {{form2.patient_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            年龄
          </template>
          {{form2.patient_age}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            性别
          </template>
          {{form2.patient_sex}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            病历号
          </template>
          {{form2.case_number}}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title="患者检查数据" :column="4" size="small" border>
        <el-descriptions-item>
          <template slot="label">
            球镜
          </template>
          {{form.Spherical_mirror}}
          <span class="unit-append">D</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            柱镜
          </template>
          {{form.Column_mirror}}
          <span class="unit-append">D</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            轴位
          </template>
          {{form.Axial_position}}
          <span class="unit-append">°</span>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            镜眼距
          </template>
          12.0
          <span class="unit-append">mm</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            平坦K值
          </template>
          {{form.K1}}
          <span class="unit-append">D</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            平坦子午线
          </template>
          {{form.K1_direction}}
          <span class="unit-append">°</span>
        </el-descriptions-item>
              
        <el-descriptions-item>
          <template slot="label">
            陡峭K值
          </template>
          {{form.K2}}
          <span class="unit-append">D</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            陡峭子午线
          </template>
          {{form.K2_direction}}
          <span class="unit-append">°</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            角膜厚度
          </template>
          {{form.T}}
          <span class="unit-append">mm</span>
        </el-descriptions-item>
              
        <el-descriptions-item>
          <template slot="label">
            前房深度
          </template>
          {{form.ACD}}
          <span class="unit-append">mm</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            白到白
          </template>
          {{form.WhitetoWhite}}
          <span class="unit-append">mm</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            
          </template>
        </el-descriptions-item>
      </el-descriptions>

      <div class="margin-top span-title">
        <span >计算结果</span>
      </div>
      <el-table
        ref="printTable"
        :data="print_table_data"
        style="width: 100%"
        size="small"
        border
        :row-class-name="tableRowClassName"
        >
        <el-table-column
          prop="xzjtqjds"
          label="选中晶体球镜度数"
          >
        </el-table-column>
        <el-table-column
          prop="xzjtzjds"
          label="选中晶体柱镜度数"
          >
        </el-table-column>
        <el-table-column
          prop="yqqj"
          label="预期球镜"
          >
        </el-table-column>
        <el-table-column
          prop="yqsg"
          label="预期散光"
          >
        </el-table-column>
        <el-table-column
          prop="zx"
          label="轴向"
          >
        </el-table-column>
        <el-table-column
          prop="dxqj"
          label="等效球镜"
          >
        </el-table-column>
      </el-table>

      <el-descriptions class="margin-top" title="晶体规格" :column="1" size="small" border style="width: 45%;">
        <el-descriptions-item>
          <template slot="label">
            人工晶状体尺寸
          </template>
          {{recommended_icl}}
        </el-descriptions-item>
      </el-descriptions>

      <div class="margin-top span-text">
        <span >蕾明视康不接收或保留任何患者数据。请为您的记录打印初始输出的副本。 更多关于蕾明视康在线计算器使用帮助，请拨打0510-85192552。</span>
      </div>
    </div>

    <el-button type="primary" v-print="'#printMe'" class="margin-top">确认打印</el-button>
  </el-dialog>
</template>

<script>
export default {
  name: 'Preview',
  data() {
    return {
      dialogVisible:false,
      print: {
        id: 'printMe',
        popTitle: '打印', // 打印配置页上方标题
        extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
        previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
        previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
        zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）   
        beforeEntryIframe() {
          // copy是ele对象的深度拷贝，修改copy对象不会更改页面中table表格的样式，只会更改打印预览生成的表格的样式
          // console.log("coopy:");
          // console.log(copy);
          const tableNode = document.querySelectorAll('.el-table__header,.el-table__body');
          for (let j = 0; j < tableNode.length; j++) {
            const tableItem = tableNode[j];
            tableItem.style.width = '100%';
            const child = tableItem.childNodes;
            for (let i = 0; i < child.length; i++) {
              const element = child[i];
              if(element.localName === 'colgroup') {
                element.innerHTML = '';
              }
            }
          }
        },
        previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
        previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
        beforeOpenCallback() {}, // 开启打印前的回调事件
        openCallback() {}, // 调用打印之后的回调事件
        closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
        url: '',
        standard: '',
        extraCss: '',
      },
      print_table_data: [],
    }
  },
  props: {
    form: {
      default: []
    },
    form2: {
      default: []
    },
    result_Spherical_mirror:{
      default:''
    },
    result_Column_mirror: {
      default: ''
    },
    result_Axial_position:{
      default:''
    },
    recommended_icl: {
      default: ''
    },
    return_tableData : {
      default: []
    },
    currentRow : {
      default: []
    },
  },
  methods: {
    init(){
      this.dialogVisible = true;
      var current_index = 0;
      for (let index = 0; index < this.return_tableData.length; index++) {
        const element = this.return_tableData[index];
        if (element == this.currentRow[0]) {
          current_index = index;
          break;
        }
      }
      for (let index = (current_index - 4) > 0 ? (current_index - 4) : 0; 
          index < this.return_tableData.length && index <= (current_index + 4); index++) {
        const element = this.return_tableData[index];
        this.print_table_data.push(element);
      }
            // this.dayin();
    },
    tableRowClassName({row}) {
      if(row == this.currentRow[0]){
        // console.log(row)
        return 'warning-row';
      }
      // if (rowIndex === 4) {
      //   return 'warning-row';
      // }
      return '';
    },
    // dayin(){
    //   const iframe = document.createElement('div');
    //   let a = this.$refs.aa.cloneNode(true);
    //   // let b = this.$refs.bb.cloneNode(true);
    //   iframe.style.width = '210mm';
    //   iframe.style.height = '297mm';
    //   iframe.appendChild(a);
    //   // iframe.appendChild(b);

    //   console.log(iframe);
    //   this.$nextTick(() => {
    //     this.$print(iframe);
    //   });
    // }
  },
  watch:{
    'dialogVisible'(newVal){
      if (!newVal) {
        this.print_table_data = [];
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  .el-table{
    .el-table__body{
      width: 100%!important;
    }
    .el-table__header{
      width: 100%!important;
    }

    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}
.logo-box-print {
  overflow: hidden;
  padding: 0;
  /* text-align: left; */
  /* box-sizing: border-box; */
  /* border-bottom: 1px solid var(--el-border-color-base); */
  /* display: flex; */
  justify-content: center;   /* 子元素水平居中 */
  align-items: center;       /* 子元素垂直居中 */
  display: -webkit-flex;
  /* justify-content: center; */
}
.img-box-print{
  /* padding-left: 30%; */
}
.img-logo-print{
  height: 45px;
}
.font-title-print{
  padding-left: 3%;
  line-height: 50px;
  font-size: 23px;
}
.span-title{
  float: left;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}
.span-text{
  font-size: 8px;
  color: #000;
  text-align: center;
}
.unit-append{
  float: right;
  color: #C4C4CC;
}
.Calculator{
  width: 45%;
  margin: 5% ;
  float: left;
  margin-top: 40px;
  padding-top: 40px;
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow:0px 0px  15px 1px rgb(214, 214, 214);
}
.Result{
  width: 40%;
  float: right;
  margin-top: 4%;
  margin-right: 4%;
}
.spacial_hidden{
  visibility: hidden;
}
.spacial_button{
  width: 60%;
  padding-left: 45%;
}
.margin-top {
  margin-top: 35px;
}
.dayin{
  margin-top: 60px;
}
</style>
