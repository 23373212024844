<template>
  <!-- <span class="foot_word">Copyright 2022 © 无锡蕾明视康科技有限公司 版权所有
    <a href="https://beian.miit.gov.cn">苏ICP备16047236号-2</a>
  </span> -->
  <div class="affix-container">
    <!-- <el-affix target=".affix-container" position="bottom" :offset="20"> -->
    <span>Copyright 2022 © 无锡蕾明视康科技有限公司 版权所有
      <a class="beian" href="https://beian.miit.gov.cn" target="_blank">苏ICP备16047236号-2</a>
    </span>
    <!-- </el-affix> -->
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.beian{
  margin-left: 30px;
  text-decoration:none;
  color: #C2C2C2;
}
.beian:hover{
  color: #343434;
}
.foot_word{
  position:absolute;
  width:100%;
  clear:both;
  height:25px;
  /* border-bottom:1px solid #e0e0e0; */
  /* border-top:1px solid #e0e0e0; */
  margin-top:20px;
  margin-bottom: 150px;
  bottom:0;
  left:0;
}
.affix-container {
  text-align: center;
  align-self: center;
  height: 60px;
  border-radius: 4px;
  padding-top: 20px;
  color: #C2C2C2;
}
</style>
