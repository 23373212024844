<template>
  <div class="logo-box">
    <span class="img-box"><img class="img-logo" src="../assets/Vision_Pro_logo.png"></span>
    <span class="font-title">后房有晶体眼屈光矫正型人工晶状体在线计算器</span>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      logimg: "",
    };
  },
};
</script>
<style scoped>
.logo-box {
  overflow: hidden;
  padding: 0;
  /* text-align: left; */
  /* box-sizing: border-box; */
  border-bottom: 1px solid var(--el-border-color-base);
  /* display: flex; */
  justify-content: center;   /* 子元素水平居中 */
  align-items: center;       /* 子元素垂直居中 */
  display: -webkit-flex;
  /* justify-content: center; */
}
.img-box{
  /* padding-left: 30%; */
}
.img-logo{
  height: 80px;
}
.font-title{
  padding-left: 3%;
  line-height: 60px;
  font-size: 35px;
}
</style>
