/**
 * @Author: FangMy
 * @ModuleName: 数字输入限制指令
 * @Date: 2022-01-25
 * 指令修饰符：
 *    .negative允许负数，默认不允许
 *    .int限制整数，默认不限制
 *
 * 指令的绑定值：允许输入数字或字符串或对象；
 *    1. 数字或字符串：表示限制小数位数；
 *    2. 对象时：
 *       decimal: 限制小数位数，默认不限制
 *       max:     限制最大值，默认不限制
 *       min:     限制最小值，默认不限制
 *
 * 示例：
 * 限制输入数字（默认不限制小数位数、仅允许输入正数）：
 * <el-input v-model="num" v-number-input />
 * 限制小数位数：
 * <el-input v-model="num" v-number-input="{ decimal: 2 }" />
 * <el-input v-model="num" v-number-input="2" />
 * 限制整数
 * <el-input v-model="num" v-number-input.int />
 * <el-input v-model="num" v-number-input="0" />
 * 允许负数
 * <el-input v-model="num" v-number-input.negative />
 * 允许负数，限制整数
 * <el-input v-model="num" v-number-input.negative.int />
 * <el-input v-model="num" v-number-input.negative="0" />
 * 限制最大最小值
 * <el-input v-model="num" v-number-input="{ min: 2, max: 5 }" />
 * <el-input v-model="num" v-number-input="{ min: 2 }" />
 * <el-input v-model="num" v-number-input.negative.int="{ max: 2 }" />
 */
export const numberInput = {
  bind(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    // ele.addEventListener('keyup', onInput(ele, binding, vnode), false);
    ele.addEventListener('blur', onInput(ele, binding, vnode), false); //防止按下后鼠标移开按键没有起来
  },
};

/**
 * 以下是指令具体执行方法
 * @param ele 指令所绑定的元素，可以用来直接操作 DOM
 * @param binding 指令绑定相关对象
    value：指令的绑定值，例如：v-my-directive="1 + 1" 中，绑定值为 2。
    arg：传给指令的参数，可选。例如 v-my-directive:foo 中，参数为 "foo"。
    modifiers：一个包含修饰符的对象。例如：v-my-directive.foo.bar 中，修饰符对象为 { foo: true, bar: true }。
 * @param vnode Vue 编译生成的虚拟节点
 * @returns 
 */
// 监听input输入并限制输入格式
function onInput(ele, binding, vnode) {
  return () => {
    let oldValue = ele.value;
    // 初始化数据 begin
    let allowNegative, onlyInt;
    let pointKeep, min, max;
    let isNegative;
    if (typeof binding.value !== 'undefined') {
      if (typeof binding.value === 'string' || typeof binding.value === 'number') {
        pointKeep = parseInt(binding.value);
      } else if (typeof binding.value === 'object') {
        // 支持新的小数点保留位配置
        min = binding.value.min;
        max = binding.value.max;
        pointKeep = parseInt(binding.value.decimal);
      }
    }
    if (typeof binding.modifiers !== 'undefined') {
      onlyInt = binding.modifiers.int;
      allowNegative = binding.modifiers.negative;
    }
    // 初始化数据 end

    // 开始处理
    let val = oldValue;
    // 传入了整数型修饰符
    if (onlyInt) {
      val = val.replace(/[^\d]/g, '');
    } else {
      // 限制仅能输入数字或小数点，并限制指定小数位数
      val = handAllowFloat(val, pointKeep);
    }

    // 处理负数
    if (allowNegative && oldValue.length >= 1) {
      isNegative = oldValue.charAt(0) === '-';
      isNegative && (val = '-' + val);
    }

    // 限制最大最小值
    if ((min != undefined && min != null) || (max != undefined && max != null)) {
      val = limitMinMax(val, min, max);
    }

    ele.value = val;
    // console.log(ele.value) parseFloat(val);

    // 输入字母时数据未同步，手动触发数据的双向绑定
    if (vnode.componentInstance) {
      // console.log('ele.value----')
      vnode.componentInstance.$emit('input', ele.value);
    } else {
      // console.log('ele.value&&&&')
      vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
    }
  };
}

// 限制最大最小值
function limitMinMax(val, min, max) {
  
  min = parseFloat(min);
  max = parseFloat(max);
  if (!isNaN(min)) {
    if (min >= 0) {
      // 不能是负数
      val = val.replace('-', '');
    }
    if (parseFloat(val) < min) {
      val = min;
      alert("输入的数字小于默认最小值：" + min);
    }
  }
  if (!isNaN(max)) {
    if (parseFloat(val) > max) {
      val = max;
      alert("输入的数字大于默认最大值：" + max);
    }
  }
  return val;
}

/**
 * 限制输入数字或小数点，并限制指定小数位数
 * @param {*} num 需要处理的数字
 * @param {*} fixed 限制小数位数，默认不限制
 */
function handAllowFloat(num, fixed) {
  
  // 清除"数字"和"."以外的字符
  let val = num.replace(/[^\d.]/g, '');

  // 只保留第一个“点”号, 清除多余的
  const idx = val.indexOf('.');
  if (!(idx === -1 || idx === val.length - 1)) {
    val = val.substr(0, idx) + '.' + val.substr(idx + 1).replace(/\./g, '');
  }
  val = val.replace(/^\./g, '0.'); // 第一个字符如果是.号，则补充前缀0

  // 期望保留的最大小数位数
  if (!isNaN(fixed) && Number.isInteger(fixed) && fixed >= 0) {
    const str = '^(\\d+)\\.(\\d{' + fixed + '}).*$';
    // 通过正则保留小数点后指定的位数
    const replacement = fixed === 0 ? '$1' : '$1.$2';
    val = val.replace(new RegExp(str), replacement);
  }
  return val;
}
